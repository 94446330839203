import * as React from 'react'

import {
  AddPhotoAlternateOutlined,
  AssuredWorkloadOutlined,
  Autorenew,
  LoginOutlined,
  PolicyOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material'
import { Container, Grid, Paper, Stack, Typography } from '@mui/material'

type CardProps = {
  title: React.ReactNode
  body: React.ReactNode
  icon: React.ReactNode
}

const Card = ({
  title,
  body,
  icon,
}: CardProps) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={4}
  >
    <Paper
      variant='outlined'
      sx={(theme) => ({
        height: '100%',
        borderColor: theme.palette.mode === 'light' ? 'text.primary' : 'primary.dark',
        bgcolor: theme.palette.mode === 'light' ? 'primary.light' : 'action.hover',
      })}
    >
      <Stack
        direction='column'
        alignItems='center'
        spacing={2}
        sx={{ p: 2 }}
      >
        {icon}
        <Typography
          variant='h5'
          component='h3'
          fontWeight={500}
          textAlign='center'
        >
          {title}
        </Typography>
        <Typography
          variant='body1'
          component='h4'
          fontWeight={300}
          textAlign='center'
        >
          {body}
        </Typography>
      </Stack>
    </Paper>
  </Grid>
)

const DetailedFeaturesSection = () => {
  return (
    <Container
      maxWidth='lg'
      sx={{ py: 10 }}
    >
      <Stack
        spacing={2}
        pb={4}
      >
        <Typography
          variant='h3'
          component='h2'
          fontWeight={500}
          textAlign='center'
        >
          Soluciones a tu medida
        </Typography>
        <Typography
          variant='h6'
          component='h2'
          fontWeight={300}
          textAlign='center'
        >
          Nuestro equipo de expertos en
          asesoría tributaria está aquí para brindarte soluciones efectivas que te ayuden a optimizar tus
          obligaciones fiscales y aprovechar al máximo las oportunidades que la legislación tributaria ofrece.
        </Typography>
      </Stack>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={3}
      >
        <Card
          title='Optimización tributaria'
          body='Analizamos tu situación financiera y diseñamos estrategias personalizadas para reducir tus impuestos.'
          icon={<Autorenew fontSize='large' />}
        />
        <Card
          title='Impuesto a las herencias'
          body='Minimiza el impacto del impuesto a las herencias con nuestra asesoría experta.'
          icon={<AddPhotoAlternateOutlined fontSize='large' />}
        />
        <Card
          title='Reorganizaciones empresariales'
          body='Colaboramos contigo para evaluar y ejecutar reorganizaciones empresariales que aumenten
           la eficiencia operativa y la ventaja fiscal.'
          icon={<AssuredWorkloadOutlined fontSize='large' />}
        />
        <Card
          title='Recuperación del IVA exportador'
          body='Te ayudamos con las regulaciones de IVA exportador'
          icon={<SpaceDashboardOutlined fontSize='large' />}
        />
        <Card
          title='Defensoría ante el SII'
          body='Representamos a tu empresa, cumplimos plazos y resolvemos disputas fiscales eficazmente.'
          icon={<PolicyOutlined fontSize='large' />}
        />
        <Card
          title='Auditorías tributarias preventivas'
          body='Garantizamos el cumplimiento fiscal de tu empresa, evitando sanciones y multas futuras'
          icon={<LoginOutlined fontSize='large' />}
        />
        {/* <Card
          title='Revisión mensual del formulario 29 de IVA'
          body='Llevamos a cabo una revisión exhaustiva de tu F29 de IVA para garantizar su precisión y cumplimiento
          con las regulaciones fiscales.'
          icon={<LoginOutlinedIcon fontSize='large' />}
        />
        <Card
          title='Confección anual formulario 22'
          body='Preparamos y presentamos el F22 de impuesto a la renta de tu empresa de manera profesional y oportuna,
          garantizando el proceso de optimización tributaria.'
          icon={<LoginOutlinedIcon fontSize='large' />}
        />
        <Card
          title='ALGO PARA NO QUEDE DESBALANCEADO'
          body='Accesos de tus usuarios a sus plataformas.'
          icon={<LoginOutlinedIcon fontSize='large' />}
        /> */}
      </Grid>
    </Container>
  )
}

export default DetailedFeaturesSection
