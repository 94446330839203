import * as React from 'react'

import { Container, Stack, Typography } from '@mui/material'

const HeroSection = () => (
  <Container maxWidth='lg'>
    <Stack
      direction='column'
      spacing={4}
      px={1}
      py='10vh'
    >
      <Typography
        textAlign='center'
        variant='h1'
        component='h1'
        fontWeight={800}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            fontSize: '3.2rem',
          },
        })}
      >
        Servicios de&nbsp;
        <span
          style={{
            background: 'var(--color-mainTitleGradient)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          asesoría tributaria
        </span>
      </Typography>
      <Typography
        textAlign='center'
        variant='h5'
        component='h2'
        fontWeight={300}
      >
        En LYC auditores tributarios, entendemos la complejidad del sistema tributario chileno y
        cómo puede afectar tus finanzas personales o empresariales.

      </Typography>
    </Stack>
  </Container>
)

export default HeroSection
