import * as React from 'react'

import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

const Flags = () => (
  <Stack
    direction='row'
    spacing={2}
    alignItems='center'
    justifyContent='space-evenly'
    sx={{ pb: 6 }}
  >
    <StaticImage
      src='../../images/landing/flags/chile.png'
      alt='Bandera de Chile'
      width={64}
      quality={90}
    />
    <StaticImage
      src='../../images/landing/flags/colombia.png'
      alt='Bandera de Colombia'
      width={64}
      quality={90}
    />
  </Stack>
)

const Testimony = () => (
  <Paper
    variant='outlined'
    sx={{ borderColor: 'primary.dark' }}
  >
    <Stack
      alignItems='center'
      spacing={1}
      p={3}
    >
      <StaticImage
        src='../../images/landing/saa.png'
        alt='Sebastian Saá'
        width={100}
        quality={90}
        style={{ borderRadius: '50%' }}
      />
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
      >
        LyC Auditores ha sido un socio esencial para Sugarblock SpA.
      </Typography>
      <Typography
        textAlign='justify'
        pb={1}
      >
        Su experiencia en contabilidad
        y tecnología ha mejorado significativamente nuestros procesos. Han demostrado un enfoque
        personalizado y una capacidad excepcional para adaptarse a las últimas tendencias
        tecnológicas como blockchain y modelos de negocio cercanos a fintech.
        Además, su compromiso y disposición para trabajar estrechamente con nosotros nos ha
        permitido comprender y aplicar soluciones efectivas para el crecimiento de nuestro negocio.
        Recomendamos encarecidamente a LyC Auditores como asesores financieros y contables
        altamente competentes y comprometidos. Recomendadísimo
      </Typography>
      <Typography
        variant='h6'
        component='p'
        fontWeight={500}
        textAlign='center'
        lineHeight={1}
      >
        Sebastian Saá
      </Typography>
      <Typography
        fontWeight={400}
        textAlign='center'
        color='text.secondary'
      >
        CEO & Co-founder Sugarblock
      </Typography>
    </Stack>
  </Paper>
)

const TestimoniesSection = () => {
  return (
    <Box
      bgcolor='action.selected'
      py={10}
    >
      <Container maxWidth='lg'>
        <Stack
          alignItems='center'
          spacing={2}
          pb={4}
        >
          <Typography
            variant='h3'
            component='h2'
            fontWeight={500}
            textAlign='center'
          >
            Países
          </Typography>
          <Typography
            variant='h6'
            component='h2'
            fontWeight={300}
            textAlign='center'
          >
            Estudio contable con alcance legal y presencia local en Chile y Colombia
          </Typography>
          <Container
            maxWidth='md'
          >
            <Flags />
            <Testimony />
          </Container>
        </Stack>
      </Container>
    </Box>
  )
}

export default TestimoniesSection
