import * as React from 'react'

import { Divider } from '@mui/material'

import { SeoHeaders } from 'shared/components'

import DetailedFeaturesSection from '../components/landing/detailed_features_section'
import HeroSection from '../components/landing/hero_section'
import TestimoniesSection from '../components/landing/testimonies_section'
import Layout from '../layouts/landing'

const IndexPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        description={(
          'Asesoría empresarial, Soluciones personalizadas'
        )}
      />
      <Layout>
        <HeroSection />
        <Divider variant='middle' />
        <DetailedFeaturesSection />
        <Divider variant='middle' />
        <TestimoniesSection />
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
